import { ref, watch, computed, reactive, toRefs } from "@vue/composition-api";
import api from "@/libs/api.js";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기

export default function useUsersList() {
  const toast = useToast();

  const maintable = ref(null);

  const tableColumns = [
    { key: "idx", label: i18n.t("label.L0055") },
    { key: "modelName", label: i18n.t("label.L0154") },
    { key: "rmk", label: i18n.t("label.L0155") },
    { key: "changeWork", label: i18n.t("label.L0064") },
  ];
  const rowNum = ref(10);
  const userAuth = store.getters["verticalMenu/adminUser"].userAuth;
  const totalRows = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 50, 100];
  const filter = ref("");
  const tunnelCodeOptions = ref([]); // 터널명 셀렉트 박스
  const tunnelCodePopup = ref([]); // popup 에서 사용되는 터널명
  const techOptions = ref([]); // 검지기 셀랙트 박스
  const yugoYnOptions = ref([]); // 유고 검지기 유무 셀랙트 박스

  const searchEndDate = ref(null);
  const searchStartDate = ref(null);
  const type = ref("equip");
  const dataMeta = computed(() => {
    const localItemsCount = maintable.value
      ? maintable.value.localItems.length
      : 0;
    return {
      from: rowNum.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: rowNum.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    };
  });
  const originItems = ref(null); // 장비List 원본 가진 변수 -> 장비 수정시 이용
  const refetchData = () => {
    maintable.value.refresh();
  };

  watch([currentPage, rowNum, type], () => {
    refetchData();
  });

  const getSelectBoxItems = async () => {
    try {
      const LanguageALLText = i18n.t("label.L0021");
      let selectBoxItems = [{ text: LanguageALLText, data: "ALL" }]; // 셀렉트 박스값 넣을 변수 초기화

      // 셀렉트 박스 값 백엔드 호출
      const response = await api.getSelectBoxItems();
      // 셀렉트 박스에 값 세팅
      const { data } = response.data;
      for (const item of data.selectBoxItems) {
        const selectBoxItem = {
          // groupId별 codeId 값
          text: item.location,
          data: item.tunnelcode,
        };
        selectBoxItems.push(selectBoxItem);
      }
      tunnelCodeOptions.value = selectBoxItems;
      // tunnelCodePopup.value = selectBoxItems;

      tunnelCodePopup.value = selectBoxItems.filter(
        // popup 에서 사용되는 셀렉트 박스값
        (item) => item.data != "ALL"
      );

    } catch (err) {
      console.log(err);
    }
  };

  const getSBTechItems = async () => {
    try {
      let selectBoxItems = [{ text: i18n.t("label.L0021"), data: "ALL" }]; // 셀렉트 박스값 넣을 변수 초기화

      // 셀렉트 박스 값 백엔드 호출
      const response = await api.getSBTechItems();
      // 셀렉트 박스에 값 세팅
      const { data } = response.data;
      for (const item of data.selectBoxItems) {
        const selectBoxItem = {
          // groupId별 codeId 값
          text: item.techname,
          data: item.techname,
        };
        selectBoxItems.push(selectBoxItem);
      }
      techOptions.value = selectBoxItems;
    } catch (err) {
      console.log(err);
    }
  };

  const getSBYogoYnItems = async () => {
    try {
      let selectBoxItems = [{ text: i18n.t("label.L0021"), data: "ALL" }]; // 셀렉트 박스값 넣을 변수 초기화

      // 셀렉트 박스 값 백엔드 호출
      const response = await api.getSBYogoYnItems();
      // 셀렉트 박스에 값 세팅
      const { data } = response.data;
      for (const item of data.selectBoxItems) {
        const selectBoxItem = {
          // groupId별 codeId 값
          text: item.yugodetectorynnm,
          data: item.yugodetectorynnm,
        };
        selectBoxItems.push(selectBoxItem);
      }
      yugoYnOptions.value = selectBoxItems;
    } catch (err) {
      console.log(err);
    }
  };

  const getList = async () => {
    try {
      await getSelectBoxItems(); // 터널코드 셀렉트 박스
      await getSBTechItems(); // 장비 종류 셀렉트 박스
      await getSBYogoYnItems(); // 카메라 유고검지기 여부 셀렉트 박스

      for (var i = 0; i < tableColumns.length; i++) {
        var t = tableColumns[i];
        if(i==0){
          t.label = i18n.t("label.L0055");
        }
        if(i==1){
          t.label = i18n.t("label.L0144");
        }
        if(i==2){
          t.label = i18n.t("label.L0017");
        }
        if(i==3){
          t.label = i18n.t("label.L0043");
        }
        if(i==4){
          t.label = i18n.t("label.L0054");
        }
        if(i==5){
          t.label = i18n.t("label.L0087");
        }
        if(i==6){
          t.label = i18n.t("label.L0052");
        }
        if(i==7){
          t.label = i18n.t("label.L0048");
        }
        if(i==8){
          t.label = i18n.t("label.L0049");
        }
        if(i==9){
          t.label = i18n.t("label.L0050");
        }
        if(i==10){
          t.label = i18n.t("label.L0145");
        }
      }

      if (type.value == "equip") {
        const response = await api.getEquipmentList({
          page: currentPage.value,
          pageSize: 10000,
        });
        const { data } = response.data;
        const {
          endRecordNum,
          page,
          pageCount,
          pageSize,
          startRecordNum,
          total,
        } = data.paging;
        // currentPage.value= page
        totalRows.value = total;
        // 1. 원본데이터 가진 변수 -> 장비 수정시 이용
        originItems.value = [...data.equipList];

        // 2. 장비 List 표시할 때 사용할 요소 저장할 변수
        var showAttrEquipList = JSON.parse(
          JSON.stringify([...data.equipList]) //깊은복사 하기 위해 JSON 사용
        );

        // 3. 레이더, 음향 필요한 요소 ->
        // equipId, tech, equipName, bound, position, detectBeginMilestone,
        // detectEndMilestone, linkDirection(검색시 방면정보 얘로 판단해서 검색됨)

        // 4. 레이더, 음향 불필요 요소  -> 검색 이용시 해당 값들또한 포함되기 떄문에 검색 결과 다르게 나옴
        var deleteEquipAttr = [
          "beginLaneDirection",
          "beginLaneIndex",
          "cameraId",
          "tech",
          "cameraIndex",
          "cameraName",
          "cameraState",
          "connectIp",
          "connectPort",
          "detectHDistance",
          "detectVDistance",
          "endLaneDirection",
          "endLaneIndex",
          "instLocation",
          "instYear",
          "linkCode",
          "mnf",
          "model",
          "name",
          "road",
          "roadName",
          "state",
          "tunnelClass",
        ];

        showAttrEquipList.forEach((each, index) => {
          // 5. 불필요 요소 삭제
          deleteEquipAttr.forEach((attr) => {
            delete each[attr];
          });

          each.directionNm =
            each.bound == 0 ? i18n.t("label.L0020") : i18n.t("label.L0019");

          // each.position == 0 ? "0" : (Number(each.position) * 1000);
          each.position =
            each.position == 0
              ? "0m"
              : Number(each.position).toFixed(2) * 1000 + " m";

          if (each.detectBeginMilestone != "") {
            each.detectBeginMilestone =
              each.detectBeginMilestone == 0
                ? "0m"
                : (Number(each.detectBeginMilestone) * 1000).toFixed(0) + " m";
          }
          if (each.detectEndMilestone != "") {
            each.detectEndMilestone =
              each.detectEndMilestone == 0
                ? "0m"
                : (Number(each.detectEndMilestone) * 1000).toFixed(0) + " m";
          }
        });
        // 6. 사용자 검색 및 표시될 필드값 가진 item만 리턴
        return showAttrEquipList;
      } else {
        const response = await api.getCameraList({
          page: currentPage.value,
          pageSize: 10000,
        });
        const { data } = response.data;
        const {
          endRecordNum,
          page,
          pageCount,
          pageSize,
          startRecordNum,
          total,
        } = data.paging;

        // currentPage.value= page
        totalRows.value = total;
        // 원본데이터 가진 변수 -> 장비 수정시 이용
        originItems.value = JSON.parse(JSON.stringify([...data.cameraList])); //깊은복사 하기 위해 JSON 사용

        // 카메라 List 표시할 때 사용할 요소 저장할 변수
        var showAttrCameraList = [...data.cameraList];
        // 1. 카메라 필요한 요소 ->
        // tech, cameraId, bound, cameraName, linkDirection, position, cameraDeviceType,
        // cameraType, resolution
        // (검색시 방면정보 얘로 판단해서 검색됨)

        // 2. 카메라 불필요 요소  -> 검색 이용시 해당 값들또한 포함되기 떄문에 검색 결과 다르게 나옴
        var deleteCameraAttr = [
          "bracketType",
          "cameraIndex",
          "direction",
          "tech",
          "techName",
          // "equipId",
          "instHeight",
          "instType",
          "linkCode",
          "roadName",
          "rtspAlias",
          "rtspUrl",
          "state",
          "yugoDetectorYn",
        ];

        showAttrCameraList.forEach((each, index) => {
          // 1. 불필요 요소 삭제( filter 이용 )
          deleteCameraAttr.forEach((attr) => {
            delete each[attr];
          });

          // // 2. 장비구분 이름 요소 추가
          // if (each.tech === "R") {
          //   each.techName = "레이더";
          // } else if (each.tech === "S") {
          //   each.techName = "음향";
          // } else {
          //   console.log('cccc')
          //   // tech = C 인경우
          //   each.techName = "카메라";
          // }

          if (each.bound == 0) {
            each.directionNm = i18n.t("label.L0020");
          } else {
            each.directionNm = i18n.t("label.L0019");
          }
          each.position =
            each.position == 0
              ? "0m"
              : (Number(each.position) * 1000).toFixed(0) + " m";

          // each.position == 0 ? "0m" : Number(each.position).toFixed(2) * 1000 + "m";
        });

        return showAttrCameraList;

        // return data.cameraList;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFunction = async (data) => {
    if (type.value == "equip") {
      await api.removeEquip(data.equipId);
    } else {
      await api.removeCamera(data.cameraId);
    }
  };

  const createFunction = async (data) => {
    if (type.value == "equip") {
      const response = await api.addEquip(data);
      return response;
    } else {
      const response = await api.addCamera(data);
      return response;
    }
  };
  const updateFunction = async (data) => {
    if (type.value == "equip") {
      await api.modifyEquip(data.equipId, data);
    } else {
      await api.modifyCamera(data.cameraId, data);
    }
  };

  return {
    userAuth,
    createFunction,
    deleteFunction,
    type,
    refetchData,
    getList,
    tableColumns,
    rowNum,
    totalRows,
    currentPage,
    dataMeta,
    perPageOptions,
    filter,
    searchEndDate,
    searchStartDate,
    maintable,
    updateFunction,
    originItems,
    getSelectBoxItems,
    tunnelCodeOptions,
    techOptions,
    yugoYnOptions,
    tunnelCodePopup,
  };
}
