<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0001')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      hideHeaderClose
      no-close-on-backdrop
      scrollable
      size="lg"
      no-close-on-esc
      body-class="bodyCustomEquip"
      footer-class="footerCustomEquip"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col>
              <fieldset class="sectionFieldset form-group">
                <!-- <legend>{{ $t("label.L0106") }}</legend> -->
                <b-row style="margin-bottom: 15px; margin-top: 15px;">
                  <b-col sm="2">
                    <label for=""
                      >{{ $t("label.L0017")
                      }}<span class="requiredStar">*</span></label
                    >
                  </b-col>
                  <!-- <b-col md="3"> -->
                  <b-col md="4">
                    <v-select
                      v-model="detectorInfo.tunnelCode"
                      :clearable="false"
                      label="text"
                      :reduce="(d) => d.data"
                      :options="sortedItems(tunnelCodePopup)"
                      :disabled="propType == 'detail' ? true : false"
                    />
                  </b-col>
                  <!-- <b-col md="2"> -->
                  <!-- <b-col md="2">
                    <label for="" style="width: 70%">
                      {{ $t("label.L0009")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="equipId"
                        rules="required|equipIdCheck"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="detectorInfo.equipId"
                          type="text"
                          :readonly="
                            propType == 'detail'
                              ? true
                              : propType == 'update'
                              ? true
                              : false
                          "
                          maxLength="40"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <b-row>
                  <!-- <b-col md="2">
                    <label for="">
                      {{ $t("label.L0175")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider>
                        <v-select
                          v-model="detectorInfo.tech"
                          :options="optionsDetector"
                          label="text"
                          :reduce="(tt) => tt.data"
                          :clearable="false"
                          :searchable="false"
                          :disabled="propType == 'detail' ? true : false"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col sm="2">
                    <label for="">
                      {{ $t("label.L0009")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="equipId"
                        rules="required|equipIdCheck"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="detectorInfo.equipId"
                          type="text"
                          :readonly="
                            propType == 'detail'
                              ? true
                              : propType == 'update'
                              ? true
                              : false
                          "
                          maxLength="40"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2" style="padding-bottom: 15px">
                    <label>
                      {{ $t("label.L0176")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="equipName"
                        rules="required|equipNameCheck"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          autocomplete="off"
                          maxlength="40"
                          v-model="detectorInfo.equipName"
                          type="text"
                          :readonly="propType == 'detail' ? true : false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="2">
                    <label> {{ $t("label.L0177") }}</label></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="name"
                        #default="{ errors }"
                        rules="kor_alpha_num"
                      >
                        <b-form-input
                          v-model="detectorInfo.mnf"
                          type="text"
                          maxLength="20"
                          :readonly="propType == 'detail' ? true : false"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2"
                    ><label for="">{{ $t("label.L0178") }} </label></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="name"
                        #default="{ errors }"
                        rules="kor_alpha_num"
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="detectorInfo.model"
                          type="text"
                          maxLength="20"
                          :readonly="propType == 'detail' ? true : false"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col md="2"> <label> 접속 IP</label></b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider rules="checkip" v-slot="{ errors }">
                        <b-form-input
                          autocomplete="off"
                          v-model="detectorInfo.connectIp"
                          type="text"
                          maxLength="16"
                          :readonly="propType == 'detail' ? true : false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2"> <label> 접속 PORT</label></b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        rules="checkport"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="detectorInfo.connectPort"
                          type="text"
                          maxLength="5"
                          :readonly="propType == 'detail' ? true : false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row> -->
              <!-- </fieldset>
            </b-col>
          </b-row>












          <b-row>
            <b-col>
              <fieldset class="sectionFieldset form-group">
                <legend>{{ $t("label.L0171") }}</legend> -->
                <b-row>
                  <b-col md="2">
                    <label>
                      {{ $t("label.L0052")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="position"
                        rules="required|decimalPoint"
                        v-slot="{ errors }"
                      >
                      <!-- <validation-provider
                        name="position"
                        rules="required|decimalPoint|check_position"
                        v-slot="{ errors }"
                      > -->
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            class="text-right"
                            autocomplete="off"
                            v-model="detectorInfo.formattedPosition"
                            type="text"
                            maxLength="5"
                            :readonly="propType == 'detail' ? true : false"
                          />
                          <b-input-group-append
                            is-text
                            style="background-color: #efefef"
                          >
                            <span
                              style="
                                margin: 0.1rem 0px 0px 0.1rem;
                                background-color: #efefef;
                              "
                              >m</span
                            >
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="2">
                    <label>
                      {{ $t("label.L0174") }}
                      <span class="requiredStar">*</span></label
                    ></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider>
                        <v-select
                          v-model="detectorInfo.instHeight"
                          :options="optionsInstHeight"
                          label="text"
                          :reduce="(tt) => tt.data"
                          :clearable="false"
                          :searchable="false"
                          :disabled="propType == 'detail' ? true : false"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <!-- <b-col md="3">
                    <b-form-group >
                      <validation-provider
                        name="instHeight"
                        rules="required|instHeightCheck"
                        v-slot="{ errors }">
                        <b-input-group class="input-group-merge">
                        <b-form-input v-model="detectorInfo.instHeight"  
                        class="text-right"
                        :formatter="(str)=>numberFormatter(str,detectorInfo.instHeight)"
                        autocomplete="off" type="text" maxLength="1"/>
                          <b-input-group-append is-text>
                            <span style="color: #999999">단</span>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <b-row>
                  <b-col md="2">
                    <label>
                      {{ $t("label.L0087")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >

                  <b-col md="4">
                    <b-form-group v-if="propType != 'detail'">
                      <b-button-group size="lm">
                        <b-button
                          variant="outline-primary"
                          @click="detectorInfo.bound = '0'"
                          :pressed="detectorInfo.bound == '0' ? true : false"
                          >{{ $t("label.L0020") }}</b-button
                        >
                        <b-button
                          variant="outline-primary"
                          @click="detectorInfo.bound = '1'"
                          :pressed="detectorInfo.bound == '1' ? true : false"
                          >{{ $t("label.L0019") }}</b-button
                        >
                      </b-button-group>
                    </b-form-group>
                    <b-form-group v-else-if="propType == 'detail'">
                      <b-form-input
                        autocomplete="off"
                        v-model="detectorInfo.boundText"
                        type="text"
                        :readonly="true"
                        maxLength="40"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">                    <label>
                      {{ $t("label.L0207")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >

                  <b-col md="4">
                    <b-form-group v-if="propType != 'detail'">
                      <b-button-group size="lm">
                        <b-button
                          variant="outline-primary"
                          @click="detectorInfo.direction = '0'"
                          :pressed="
                            detectorInfo.direction == '0' ? true : false
                          "
                          >{{ $t("label.L0180") }}</b-button
                        >
                        <b-button
                          variant="outline-primary"
                          @click="detectorInfo.direction = '1'"
                          :pressed="
                            detectorInfo.direction == '1' ? true : false
                          "
                          >{{ $t("label.L0181") }}
                        </b-button>
                      </b-button-group>
                    </b-form-group>
                    <b-form-group v-else-if="propType == 'detail'">
                      <b-form-input
                        autocomplete="off"
                        v-model="detectorInfo.directionText"
                        type="text"
                        :readonly="true"
                        maxLength="40"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col md="2" style="padding-bottom: 15px">
                    <label> 연도</label></b-col
                  >
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        rules="maxLengthYear"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          class="text-right"
                          v-model="detectorInfo.instYear"
                          type="text"
                          maxLength="4"
                          :formatter="(str) => numberFormatter(str)"
                          autocomplete="off"
                          :readonly="propType == 'detail' ? true : false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row style="min-height: 59.99px">
                  <b-col md="2" style="padding-bottom: 15px">
                    <label> 장소</label></b-col
                  >

                  <b-col
                    md="6"
                    style="padding-top: 5px"
                    v-if="propType != 'detail'"
                  >
                    <b-form-group>
                      <b-button-group size="sm" style="width: 350px">
                        <b-button
                          @click="detectorInfo.instLocation = '기본구간'"
                          :pressed="
                            detectorInfo.instLocation == '기본구간'
                              ? true
                              : false
                          "
                          style="
                            width: 64.17px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                          variant="outline-primary"
                          >기본 구간</b-button
                        >
                        <b-button
                          @click="detectorInfo.instLocation = '연결구간'"
                          :pressed="
                            detectorInfo.instLocation == '연결구간'
                              ? true
                              : false
                          "
                          style="
                            width: 64.17px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                          variant="outline-primary"
                          >연결 구간</b-button
                        >
                        <b-button
                          @click="detectorInfo.instLocation = '터널'"
                          :pressed="
                            detectorInfo.instLocation == '터널' ? true : false
                          "
                          style="
                            width: 64.17px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                          variant="outline-primary"
                          >터널</b-button
                        >
                        <b-button
                          @click="detectorInfo.instLocation = '교량'"
                          :pressed="
                            detectorInfo.instLocation == '교량' ? true : false
                          "
                          style="
                            width: 64.17px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                          variant="outline-primary"
                          >교량</b-button
                        >
                        <b-button
                          @click="detectorInfo.instLocation = 'etc'"
                          :pressed="
                            detectorInfo.instLocation == 'etc' ? true : false
                          "
                          style="
                            width: 64.17px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                          variant="outline-primary"
                          >기타</b-button
                        >
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-else-if="propType == 'detail'">
                    <b-form-group>
                      <b-form-input
                        autocomplete="off"
                        v-model="detectorInfo.instLocation"
                        type="text"
                        :readonly="true"
                        maxLength="40"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <validation-provider
                        name="name"
                        #default="{ errors }"
                        rules="kor_alpha_num"
                      >
                        <b-form-input
                          v-show="detectorInfo.instLocation == 'etc'"
                          autocomplete="off"
                          v-model="detectorInfo.instLocationEtc"
                          type="text"
                          maxLength="20"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row> -->
                <b-row> </b-row>
              </fieldset>
            </b-col>
          </b-row>

          <!-- <b-row>
            <b-col>
              <fieldset class="sectionFieldset form-group">
                <legend>{{ $t("label.L0173") }}</legend>
                <b-row>
                  <b-col md="2"
                    ><label for="">시작 {{ $t("label.L0052") }}</label>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        name="detect_begin_milestone"
                        rules="decimalPoint"
                        v-slot="{ errors }"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            class="text-right"
                            autocomplete="off"
                            v-model="detectorInfo.detectBeginMilestone"
                            maxLength="5"
                            type="text"
                            :readonly="propType == 'detail' ? true : false"
                          />
                          <b-input-group-append is-text>
                            <span style="color: #999999">km</span>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <label for=""> 종료 {{ $t("label.L0052") }}</label></b-col
                  >
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        name="detect_end_milestone"
                        rules="decimalPoint"
                        v-slot="{ errors }"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            class="text-right"
                            autocomplete="off"
                            v-model="detectorInfo.detectEndMilestone"
                            maxLength="5"
                            type="text"
                            :readonly="propType == 'detail' ? true : false"
                          />
                          <b-input-group-append is-text>
                            <span style="color: #999999">km</span>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="2"><label for="">세로 길이</label></b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider v-slot="{ errors }">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            class="text-right"
                            autocomplete="off"
                            v-model="detectorInfo.detectVDistance"
                            :formatter="(str) => numberFormatter(str)"
                            maxLength="3"
                            type="text"
                            :readonly="propType == 'detail' ? true : false"
                          />
                          <b-input-group-append is-text>
                            <span style="color: #999999">m</span>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="2"><label for="">가로 길이</label></b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider rules="" v-slot="{ errors }">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            class="text-right"
                            autocomplete="off"
                            v-model="detectorInfo.detectHDistance"
                            :formatter="(str) => numberFormatter(str)"
                            type="text"
                            maxLength="3"
                            :readonly="propType == 'detail' ? true : false"
                          />
                          <b-input-group-append is-text>
                            <span style="color: #999999">m</span>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
            </b-col>
          </b-row> -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
export default {
  name: "equipPopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButtonGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    propTitle: String,
    propType: String,
    tunnelCodePopup: Array,
  },
  data() {
    return {
      gsGlobalTarget: {
        directionUp:
          this.$store.getters["settingConfig/getTarget"]("gs").direction.up,
        directionDown:
          this.$store.getters["settingConfig/getTarget"]("gs").direction.down,
      },
      detectorInfo: {
        tech: "", // 검지기 종류
        tunnelCode: "", // 터널코드
        equipName: "", // 검지기 명칭
        equipId: "", // 검지기 아이디
        road: "", // 노선
        direction: "0", // 방향
        connectIp: "", // 접속 ip
        connectPort: "", // 접속 port
        detectBeginMilestone: 0, // 검지 시작 이정
        detectEndMilestone: 0, // 검지 종료 이정
        detectVDistance: 0, // 검지 세로 길이
        detectHDistance: 0, // 검지 가로 길이
        beginLaneDirection: "", // 시작 차로 방향
        beginLaneIndex: 0, // 시작 차로 순번
        endLaneDirection: "", // 종료 차로 방향
        endLaneIndex: 0, // 종료 차로 순번
        instLocation: "기본구간", // 설치 장소
        instLocationEtc: "", // 설치 장소 (기타)
        tunnelClass: "1", // 연장 등급
        position: 0, // 설치 이정
        formattedPosition : 0, // 설치이정 m 변경 
        mnf: "", //제조사
        model: "", // 모델명
        instYear: "", // 설치연도
        linkCode: "", // 링크 코드
        instHeight: 0,
        resolution: "",
        bound: 0,
      },
      optionsBound: [
        {
          text: this.$t("label.L0020"),
          data: "0",
        },
        { text: this.$t("label.L0019"), data: "1" },
      ],
      optionLevel: [
        {
          text: "1등급",
          data: "1",
        },
        { text: "2등급", data: "2" },
        { text: "3등급", data: "3" },
        { text: "4등급", data: "4" },
      ],
      optionInstall: [
        {
          label: "기본 구간",
          data: "기본구간",
        },
        { label: "연결 구간", data: "연결구간" },
        {
          label: "터널",
          data: "터널",
        },
        { label: "교량", data: "교량" },
        { label: "기타", data: "etc" },
      ],
      optionsInstHeight: [
        {
          // text: "1단",
          text: this.$t("label.L0190"),
          data: "0",
        },
        {
          // text: "2단",
          text: this.$t("label.L0191"),
          data: "1",
        },
        {
          // text: "3단",
          text: this.$t("label.L0192"),
          data: "2",
        },
      ],
      // optionsDetector: [
      //   {
      //     text: "IDS",
      //     data: "R",
      //   },
      //   {
      //     text: "VDS",
      //     data: "V",
      //   },
      //   {
      //     text: "영상 검지기",
      //     data: "C",
      //   },
      //   {
      //     text: "스마트 교차로",
      //     data: "S",
      //   },
      // ],
      optionsDetector: [
        {
          text: "레이더",
          text: this.$t("label.L0029"),
          // text: "레이더",
          data: "R",
        },
        // {
        //   text: this.$t("label.L0030"),
        //   // text: "음향",
        //   data: "S",
        // },
      ],
      errorMsg: "",
      options: [],
      optionsItemEvaluation: [],
    };
  },
  created() {
    // this.initSetData();
  },
  methods: {
    sortedItems(items) {
      return items.slice().sort((a, b) => {
        if (a.text == "All") return -1;
        if (b.text == "All") return 1;
        // 이름을 기준으로 오름차순 정렬
        return a.text.localeCompare(b.text); // 음수: a가 b보다 앞, 0: a와 b가 동일, 양수: a가 b보다 뒤
      });
    },
    strThreeFormatter(d, e) {
      if (Number(d)) {
        return e;
      }
      if (d.length > 3) {
        return e;
      } else {
        return d;
      }
    },
    initSetData() {
      // let roadConfig = this.$storage.data[this.$storage.data["local"]];
      // this.detectorInfo.road = roadConfig[0].road;
      // this.linkCode = roadConfig[0].linkCode;
    },
    pointerCalcFunction() {
      if (/\.$/.test(this.detectorInfo.detectBeginMilestone)) {
        this.detectorInfo.detectBeginMilestone += "00";
      }
      if (/\.$/.test(this.detectorInfo.detectEndMilestone)) {
        this.detectorInfo.detectEndMilestone += "00";
      }
    },
    async onBtnOk(data) {
      data.preventDefault();
      const success = await this.$refs.simpleRules.validate();
      if (success) {
        if (!this.validationMileston) {
          this.$swal({
            heightAuto: false,
            title: "$t('message.M0011')",
            icon: "info",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: $t("button.B0001"),
            cancelButtonText: $t("button.B0002"),
            reverseButtons: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-primary ml-1",
              cancelButton: "btn btn-outline-danger",
            },
            buttonsStyling: false,
          });
          return "";
        }
        this.pointerCalcFunction();
        // this.detectorInfo.linkCode =
        //   this.detectorInfo.bound == 0
        //     ? "LNK-0450-MCT"
        //     : "LNK-0450-CMT";

        // if(this.detectorInfo.bound == 0){// 하행
        //   this.detectorInfo.road =  this.gsGlobalTarget.directionDown;
        // }else if (this.detectorInfo.bound == 1) { // 상행
        //   this.detectorInfo.road =  this.gsGlobalTarget.directionUp;
        // }

        this.detectorInfo.position = this.changePositionFormat(this.detectorInfo.formattedPosition, "km");

        if ("etc" === this.detectorInfo.instLocation)
          this.detectorInfo.instLocation = this.detectorInfo.instLocationEtc;
        if (this.propType == "update") {
          await this.nullActFunction();
          this.$emit("update-obj", this.detectorInfo);
          this.hideFunction();
        } else {
          this.$emit("create-obj", this.detectorInfo);
        }
      }
    },
    onBtnCancel() {},
    detailpopup(data) {
      if (data) {
        this.detectorInfo = { ...data };
        this.detectorInfo.formattedPosition = this.changePositionFormat(this.detectorInfo.position, "m")
        this.detectorInfo.boundText =
          this.detectorInfo.bound == 0
            ? this.$t("label.L0020")
            : this.$t("label.L0019");
        this.detectorInfo.directionText =
          this.detectorInfo.direction == "0"
            ? this.$t("label.L0180")
            : this.$t("label.L0181");
      }
      this.initSetData();

      if (
        ["기본구간", "연결구간", "터널", "교량"].indexOf(
          this.detectorInfo.instLocation
        ) === -1
      ) {
        // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제 해결
        if (this.detectorInfo.instLocation !== "") {
          this.detectorInfo.instLocationEtc = this.detectorInfo.instLocation;
          this.detectorInfo.instLocation = "기타";
        }
      }

      this.$refs.data.show();
    },
    updatepopup(data) {
      if (data) {
        // this.detectorInfo = { ...data.item };
        this.detectorInfo = { ...data };
        this.detectorInfo.formattedPosition = this.changePositionFormat(this.detectorInfo.position, "m")
      }

      this.initSetData();

      if (
        ["기본구간", "연결구간", "터널", "교량"].indexOf(
          this.detectorInfo.instLocation
        ) === -1
      ) {
        // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제 해결
        if (this.detectorInfo.instLocation !== "") {
          this.detectorInfo.instLocationEtc = this.detectorInfo.instLocation;
          this.detectorInfo.instLocation = "etc";
        }
      }

      this.$refs.data.show();
    },
    createpopup() {
      let defaultTunnelCode = this.tunnelCodePopup[0].data; // 모든 터널중 첫번째 터널로 설정
      this.detectorInfo = {
        tech: "R", // 검지기 종류
        tunnelCode: defaultTunnelCode, // 터널코드
        equipName: "", // 검지기 명칭
        equipId: "", // 검지기 아이디
        road: "", // 노선
        direction: "0", // 방향
        connectIp: "", // 접속 ip
        connectPort: "", // 접속 port
        detectBeginMilestone: null, // 검지 시작 이정
        detectEndMilestone: null, // 검지 종료 이정
        detectVDistance: null, // 검지 세로 길이
        detectHDistance: null, // 검지 가로 길이
        beginLaneDirection: "", // 시작 차로 방향
        beginLaneIndex: null, // 시작 차로 순번
        endLaneDirection: "", // 종료 차로 방향
        endLaneIndex: null, // 종료 차로 순번
        instLocation: "", // 설치 장소
        instLocationEtc: "", // 설치 장소 (기타)
        tunnelClass: "1", // 연장 등급
        position: null, // 설치 이정
        formattedPosition : null, 
        mnf: "", //제조사
        model: "", // 모델명
        instYear: "", // 설치연도
        linkCode: "", // 링크 코드
        instHeight: "0",
        resolution: "",
        bound: "0",
      };
      this.initSetData();

      this.$refs.data.show();
    },
    hideFunction() {
      this.$refs["data"].hide();
    },
    nullActFunction() {
      this.detectorInfo.detectVDistance =
        this.detectorInfo.detectVDistance == ""
          ? null
          : this.detectorInfo.detectVDistance;
      this.detectorInfo.detectHDistance =
        this.detectorInfo.detectHDistance == ""
          ? null
          : this.detectorInfo.detectHDistance;
      this.detectorInfo.beginLaneIndex =
        this.detectorInfo.beginLaneIndex == ""
          ? null
          : this.detectorInfo.beginLaneIndex;
      this.detectorInfo.endLaneIndex =
        this.detectorInfo.endLaneIndex == ""
          ? null
          : this.detectorInfo.endLaneIndex;
    },
    changePositionFormat(value, type){
      if(type === "m"){
        if (value) {
          return (value * 1000).toFixed(0);
        }else {
          return '';
        }
      }else if(type === "km"){
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          return (numValue / 1000).toFixed(2);
        }else {
          return '';
        }
      }else{
        return '';
      }
    },
    
  },
  computed: {
    validationMileston() {
      if (
        // 시작이정이 종료이정보다 클경우.
        this.detectorInfo.detectBeginMilestone != null &&
        this.detectorInfo.detectBeginMilestone != "" &&
        this.detectorInfo.detectEndMilestone != null &&
        this.detectorInfo.detectEndMilestone != ""
      ) {
        return this.detectorInfo.detectBeginMilestone >
          this.detectorInfo.detectEndMilestone
          ? false
          : true;
      }
      return true;
    },
    
  },
  mounted() {},
};
</script>
<style scoped>
.sectionFieldset {
  border: solid 1px rgba(0, 0, 0, 0.125);
  padding-top: 0px;
  padding-right: 12px;
  padding-bottom: 0px;
  padding-left: 12px;
}

.sectionFieldset legend {
  width: inherit;
  /* Or auto */
  padding: 0 10px;
  /* To give a bit of padding on the left and right */
  border-bottom: none;
  font-weight: bold;
  font-size: 12px;
}
.sectionFieldset .custom-control-inline {
  margin-right: 2rem;
}
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}
.input-group-text {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #efefef;
}
.input-group-merge {
  margin-left: 0px;
}
</style>
<style>
::v-deep .mcontentOne {
  height: 1000px !important;
  width: 1800px !important;
}
.rowSize {
  text-align: left;
  align-items: center;
  padding-bottom: 3px;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.bodyCustomEquip {
  /* padding: 1rem 1.4rem 0.2rem 1.4rem; */
  padding: 0.5rem 0.7rem 0.3rem 0.7rem;

}
.footerCustomEquip {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 17px !important;
}
</style>
