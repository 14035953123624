<template>
  <div>
    <b-card class="col-12 d-flex flex-column" no-body style="min-height: 85vh">
      <b-tabs class="mt-2">
        <b-tab
          v-for="item in searchOptions"
          :key="item"
          :title="item"
          @click="tabFunction(item)"
        >
          <template #title>
            <feather-icon :icon="item == RadioIcon ? 'RadioIcon' :
            'CameraIcon'" />
            <span>{{ item }}</span>
          </template>
        </b-tab>
      </b-tabs>
      <b-list-group class="my-2">
        <b-list-group-item>
          <b-row align-v="center">
            <b-col md="4">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label for="" style="width: 110px; font-size: 1rem">{{
                    $t("label.L0017")
                  }}</label>
                </b-col>
                <b-col md="8">
                  <v-select
                    v-model="SB_tunnelCodeData"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(tunnelCodeOptions)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col md="4" v-if="type == 'equip'">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label for="" style="">{{
                    $t("label.L0058")
                  }}</label>
                </b-col>
                <b-col md="8">
                  <v-select
                    v-model="SB_techCodeData"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(techOptions)"
                  />
                </b-col>
              </b-row>
            </b-col> -->
            <b-col md="4" v-if="type == 'camera'">
              <b-row align-v="center">
                <b-col md="3" style="padding-left: 0px">
                  <label for=""                     style="
                      font-size: 1rem;
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: normal;
                    ">{{
                    $t("label.L0071")
                  }}</label>
                </b-col>
                <b-col md="8">
                  <v-select
                    v-model="SB_yugoYnData"
                    :clearable="false"
                    label="text"
                    :reduce="(d) => d.data"
                    :options="sortedItems(yugoYnOptions)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="filterInput"
                  :placeholder="$t('message.M0005')"
                  v-model="tempfilter"
                  type="search"
                  @keyup.enter="doFilter"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="doFilter">{{
                    $t("button.B0008")
                  }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <!-- <b-col align="right">
              <b-button v-if="userAuth == 'A'" variant="primary" @click="addRow()">
                {{ $t("label.L0140") }}
              </b-button>
            </b-col> -->
          </b-row>
        </b-list-group-item>

      </b-list-group>
      <b-row class="padding-base-12 flex-grow-1">
        <b-table
          sticky-header
          ref="maintable"
          striped
          hover
          responsive
          :fields="fields"
          :items="filterItems"
          :per-page="rowNum"
          :sort-by.sync="sortValue"
          sort-desc.sync="true"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :current-page="currentPage"
          show-empty
          :empty-text="$t('message.M0008')"
          :empty-filtered-text="$t('message.M0009')"
          style="max-height: 100%"
          class="mb-0 text-center"
        >
          <!-- 63 -->
          <template #cell(idx)="data">
            {{ rowNum * (currentPage - 1) + (data.index + 1) }}
          </template>


          <template #cell(work)="data">
            <b-button-group size="sm" v-if="userAuth == 'A'">
              <b-button variant="outline-primary" @click="updateRow(data)">{{
                $t("button.B0006")
              }}</b-button>
              <b-button variant="outline-primary" @click="deleteRow(data)">{{
                $t("button.B0007")
              }}</b-button>
            </b-button-group>
            <b-button-group size="sm" v-else>
              <b-button variant="outline-primary" @click="detailRow(data)">{{
                $t("button.B0011")
              }}</b-button>
            </b-button-group>
          </template>
          <!-- <template #cell(tech)="data">
            {{ techString[data.item.tech] ? techString[data.item.tech]() : "" }}
          </template> -->
          <template #cell(techName)="data">
            {{ data.item.techName }}
            <!-- {{ data.item.tech }} -->
          </template>
          <!-- <template #cell(idEquip)="data">
              {{ data.item.equipId }}/{{ data.item.equipName }}
            </template>
           -->
          <!-- <template #cell(idCamera)="data">
              {{ data.item.cameraId }}/{{ data.item.cameraName }}
            </template> -->
          <!-- <template #cell(roadName)="data">
                 <b-badge
                pill
                variant="light-success"
                >
                {{ data.item.roadName }}
                </b-badge>
              </template> -->
          <template #cell(directionNm)="data">
            <b-badge
              pill
              variant="light-primary"
              v-if="data.item.directionNm == $t('label.L0020')"
            >
              <!-- {{ boundNameString[data.item.bound] }} -->
              {{ $t("label.L0020") }}
              <!-- {{ data.item.linkDirection }} -->
            </b-badge>
            <b-badge pill variant="light-warning" v-else>
              <!-- {{ boundNameString[data.item.bound] }} -->
              {{ $t("label.L0019") }}
              <!-- {{ data.item.linkDirection }} -->
            </b-badge>
          </template>

          <!-- 터널명 -->
          <template #cell(location)="data">
            <b-badge pill variant="light-success">
              {{ data.item.location }}
            </b-badge>
          </template>

          <!-- <template #cell(location)="data">
              <b-badge
                pill
                variant="light-success"
                v-if="data.item.location == '남산2호터널'"
              >
                남산2호터널
              </b-badge>
              <b-badge
                pill
                variant="light-success"
                v-else-if="data.item.location == '남산3호터널'"
              >
                남산3호터널
              </b-badge>
            </template> -->
        </b-table>
      </b-row>

      <b-row class="my-2" align-v="center" style="bottom: 0; width: 100%;">
        <b-col md="1" class="ml-1">
          <b-form-group class="mb-0">
            <v-select
              id="getListSelect"
              v-model="rowNum"
              size="sm"
              :options="perPageOptions"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <!-- {{$t("label.L0021")}} {{ dataMeta.of }} {{$t("label.L0111")}} {{ dataMeta.from }} ~
          {{ dataMeta.to }} {{$t("label.L0112")}} -->
          <!-- {{ currentPage }} -->
          {{ $t("label.L0021") }} {{ totalRows }} {{ $t("label.L0111") }}
          {{ rowNum * (currentPage - 1) + 1 }} ~
          {{
            rowNum * currentPage < totalRows ? rowNum * currentPage : totalRows
          }}
          {{ $t("label.L0112") }}
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            first-number
            last-number
            :per-page="rowNum"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <equip-modal
      ref="updateE"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
      @update-obj="updateEquipFunction"
    />
    <equip-modal
      ref="createE"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
      @create-obj="createEquipFunction"
    />
    <equip-modal
      ref="detailE"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
    />
    <camera-modal
      ref="updateC"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
      @update-obj="updateCameraFunction"
    />
    <camera-modal
      ref="createC"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
      @create-obj="createCameraFunction"
    />
    <camera-modal
      ref="detailC"
      :propTitle="propTitle"
      :propType="propType"
      :tunnelCodePopup="tunnelCodePopup"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import dayjs from "dayjs";
import equipModal from "./components/equipPopup.vue";
import cameraModal from "./components/cameraPopup.vue";
import equipInterface from "./equipInterface";
import {
  BRow,
  BCol,
  BFormGroup,
  BTable,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroupPrepend,
  BFormSelect,
  BPagination,
  BButtonGroup,
  BCard,
  BTabs,
  BTab,
  BBadge,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "admin_bulletinboard",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BButtonGroup,
    BCard,
    vSelect,
    BTabs,
    BTab,
    BBadge,
    equipModal,
    cameraModal,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      checkResetCurrent: true,
      sortValue: "location",
      filterItems: [],
      SB_tunnelCodeData: "ALL", // 터널명 변수
      SB_techCodeData: "ALL", // 검지기 종류 변수
      SB_yugoYnData: "ALL", // 유고 검지기 유무 변수
      tempfilter: "",
      items: [],
      filterOn: [],
      filter: "",
      techString: {
        // R: "레이더",
        // S: "음향",
        R: () => {
          {
            this.$t("label.L0029");
          }
        },
        S: () => {
          {
            this.$t("label.L0030");
          }
        },
        // C: "영상 검지기",
      },
      // techString: {
      //   R: () => "IDS",
      //   V: () => "VDS",
      //   C: () => "영상 검지기",
      //   S: () => "스마트 교차로",
      // },
      // boundNameString: {
      //   0: () => "시점",
      //   1: () => "종점",
      // },
      // boundNameString: {
      //   0: $t("label.L0020"),
      //   1: $t("label.L0019"),
      // },
      propType: "create",
      propTitle: this.$t("label.L0141"),
      searchType: this.$t("label.L0106"),
      fields: [],
      detectorFields: [
        { key: "idx", label: this.$t("label.L0055"), thStyle: { width: "6%" } },
        {
          key: "location",
          label: this.$t("label.L0017"),
          thStyle: { width: "10%" },
        },
        // { key: "techName", label: this.$t("label.L0058"), thStyle: { width: "7%" } },
        { key: "equipId", label: this.$t("label.L0069"), thStyle: { width: "15%" } },
        {
          key: "equipName",
          label: this.$t("label.L0065"),
          thStyle: { width: "12%" },
        },
        // { key: "instHeight", label: "설치 높이", thStyle: { width: '13%' } },
        // { key: "roadName", label: "노선" },
        // { key: "bound", label: $t("label.L0087"), thStyle: { width: '10%' } },
        {
          key: "directionNm",
          label: this.$t("label.L0087"),
          thStyle: { width: "8%" },
        },
        // { key: "position", label: "설치 이정", thStyle: { width: '10%' } , formatter: (value, key, item) => {
        //   return item.position.toFixed(2)+'km';
        // },},
        {
          key: "position",
          label: this.$t("label.L0061"),
          class :"textRight",
          thStyle: { width: "10%" },
          // formatter: (value, key, item) => {
          //   if(item.position == null || item.position == '') return '';
          //   return  Number(item.position).toFixed(2) * 1000 +'m';
          // }
        },
        // {
        //   key: "detectBeginMilestone",
        //   label: this.$t("label.L0062"),
        //   thStyle: { width: "10%" },
        //   // formatter: (value, key, item) => {
        //   //   if(item.detectBeginMilestone == null||item.detectBeginMilestone == '') return '';
        //   //   return  Number(item.detectBeginMilestone).toFixed(2) * 1000 +'m';
        //   // },
        // },
        // {
        //   key: "detectEndMilestone",
        //   label: this.$t("label.L0062"),
        //   thStyle: { width: "10%" },
        //   //  formatter: (value, key, item) => {
        //   //   if(item.detectEndMilestone == null||item.detectEndMilestone == '') return '';
        //   //   return  Number(item.detectEndMilestone).toFixed(2) * 1000 +'m';
        //   // },
        // },
        {
          key: "work",
          label:
            this.$store.getters["verticalMenu/adminUser"].userAuth == "U"
              ? this.$t("button.B0011")
              : this.$t("label.L0064"),
          thStyle: {
            width:
              this.$store.getters["verticalMenu/adminUser"].userAuth == "U"
                ? "9%"
                : "12%",
          },
        },
      ],

      cameraFields: [
        { key: "idx", label: this.$t("label.L0055"), thStyle: { width: "6%" } },
        {
          key: "location",
          label: this.$t("label.L0017"),
          thStyle: { width: "10%" },
        },
        {
          key: "cameraId",
          label: this.$t("label.L0069"),
          thStyle: { width: "15%" },
        },
        {
          key: "cameraName",
          label: this.$t("label.L0065"),
          thStyle: { width: "13%" },
        },
        {
          key: "directionNm",
          label: this.$t("label.L0087"),
          thStyle: { width: "5%" },
        },
        {
          key: "position",
          label: this.$t("label.L0061"),
          class :"textRight",
          thStyle: { width: "7%" },
          //         formatter: (value, key, item) => {
          //   if(item.position == null||item.position == '') return '';
          //   return  Number(item.position).toFixed(2) * 1000 +'m';
          // }
        },
        // { key: "position", label: "설치 이정", thStyle: { width: '10%' } , formatter: (value, key, item) => {
        //   return item.position.toFixed(2)+'km';
        // },},
        // {
        //   key: "cameraDeviceType",
        //   label: this.$t("label.L0066"),
        //   thStyle: { width: "7%" },
        // },
        {
          key: "yugoDetectorYnNm",
          label: this.$t("label.L0071"),
          thStyle: { width: "10%" },
        },
        // {
        //   key: "cameraType",
        //   label: this.$t("label.L0067"),
        //   thStyle: { width: "7%" },
        // },
        // {
        //   key: "resolution",
        //   label: this.$t("label.L0068"),
        //   thStyle: { width: "7%" },
        // },
        {
          key: "work",
          label:
            this.$store.getters["verticalMenu/adminUser"].userAuth == "U"
              ? this.$t("button.B0011")
              : this.$t("label.L0064"),
          thStyle: {
            width:
              this.$store.getters["verticalMenu/adminUser"].userAuth == "U"
                ? "9%"
                : "12%",
          },
        },
      ],
      searchOptions: [this.$t("label.L0106"), this.$t("label.L0031")],
      RadioIcon: this.$t('label.L0106'),
      CameraIcon: this.$t('label.L0031'),
    };
  },
  setup() {
    // const { t } = useI18n();
    // const equipInterface = computed(() => useUsersList({ t }));
    const {
      userAuth,
      refetchData,
      getList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      // filter,
      maintable,
      searchEndDate,
      searchStartDate,
      type,
      deleteFunction,
      createFunction,
      updateFunction,
      originItems,
      tunnelCodeOptions,
      tunnelCodePopup,
      techOptions,
      yugoYnOptions,
    } = equipInterface();

    return {
      userAuth,
      refetchData,
      getList,
      tableColumns,
      rowNum,
      totalRows,
      currentPage,
      dataMeta,
      perPageOptions,
      // filter,
      maintable,
      searchEndDate,
      searchStartDate,
      type,
      deleteFunction,
      createFunction,
      updateFunction,
      originItems,
      tunnelCodeOptions,
      tunnelCodePopup,
      techOptions,
      yugoYnOptions,
    };
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      // locale이 변경될 때 실행할 로직을 여기에 추가
      this.refreshFunction();
      this.searchOptions[0] = this.$t('label.L0106');
      this.RadioIcon = this.$t('label.L0106');
      this.searchOptions[1] = this.$t('label.L0031');
      this.CameraIcon = this.$t('label.L0031');

      for (var i = 0; i < this.detectorFields.length; i++) {
        var detectorTableFields = this.detectorFields[i];
        if(i==0){
          detectorTableFields.label = this.$t("label.L0055");
        }
        if(i==1){
          detectorTableFields.label = this.$t("label.L0017");
        }
        if(i==2){
          detectorTableFields.label = this.$t("label.L0059");
        }
        if(i==3){
          detectorTableFields.label = this.$t("label.L0060");
        }
        if(i==4){
          detectorTableFields.label = this.$t("label.L0087");
        }
        if(i==5){
          detectorTableFields.label = this.$t("label.L0061");
        }
        if(i==6){
          detectorTableFields.label = this.$t("label.L0064");
        }
      }
      for (var i = 0; i < this.cameraFields.length; i++) {
        var cameraTableFields = this.cameraFields[i];
        if(i==0){
          cameraTableFields.label = this.$t("label.L0055");
        }
        if(i==1){
          cameraTableFields.label = this.$t("label.L0017");
        }
        if(i==2){
          cameraTableFields.label = this.$t("label.L0069");
        }
        if(i==3){
          cameraTableFields.label = this.$t("label.L0065");
        }
        if(i==4){
          cameraTableFields.label = this.$t("label.L0087");
        }
        if(i==5){
          cameraTableFields.label = this.$t("label.L0061");
        }
        if(i==6){
          cameraTableFields.label = this.$t("label.L0071");
        }
        if(i==7){
          cameraTableFields.label = this.$t("label.L0064");
        }
      }

      // this.updateVariablesBasedOnLocale();
    }
  },
  computed: {
  },
  methods: {
    sortedItems(items) {
      return items.slice().sort((a, b) => {
        if (a.text == 'All') return -1;
        if (b.text == 'All') return 1;
        // 이름을 기준으로 오름차순 정렬
        return a.text.localeCompare(b.text); // 음수: a가 b보다 앞, 0: a와 b가 동일, 양수: a가 b보다 뒤
      });
    },
    // b-table 의 item 을 검색 기준으로 업데이트
    setfilterItem() {
      let filterItem = null; // b-table 에 표시할 item 담을 변수 초기화

      // 1. 터널명 검색 선택 안됐을 경우
      if (this.SB_tunnelCodeData == "ALL") {
        filterItem = this.items;
      } else {
        // 2. 터널명 선택됐을 경우
        filterItem = this.items.filter((item) => {
          // 장비ID에서 터널코드값(ex:NS2)찾기
          return item.tunnelCode.includes(this.SB_tunnelCodeData);
        });
      }

      // 3. 검지기 탭의 장비종류 검색 선택 됐을 경우 실행
      if (this.SB_techCodeData != "ALL" && this.type == "equip") {
        filterItem = filterItem.filter((item) => {
          // 장비 종류에서 장비종류 이름값(ex:레이더) 찾기
          return item.techName.includes(this.SB_techCodeData);
        });
      } else if (this.SB_yugoYnData != "ALL" && this.type == "camera") {
        filterItem = filterItem.filter((item) => {
          // 카메라 탭의 유고검지기 여부 선택 됐을 경우 실행
          return item.yugoDetectorYnNm.includes(this.SB_yugoYnData);
        });
      }

      // 페이징 처리 위한 로직
      this.totalRows = filterItem.length; // 검색어 및 터널명으로 필터링 된 Item 수
      if (!this.checkResetCurrent) {
        // 페이지 1로 이동하도록 하기 위해
        this.currentPage = 1;
        this.checkResetCurrent = true;
      }

      // b-table 의 item 을 검색 기준으로 업데이트
      this.filterItems = filterItem;
    },
    // 검색어 있는 경우 호출
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering

      // 페이징 처리 위한 로직
      this.totalRows = filteredItems.length;
      if (!this.checkResetCurrent) {
        this.currentPage = 1;
        this.checkResetCurrent = true;
      }
    },

    doFilter() {
      // 검색 버튼 클릭시 동작
      this.filter = this.tempfilter; // 검색어 입력값
      // b-table 의 item 을 검색 기준으로 업데이트
      this.setfilterItem();

      this.checkResetCurrent = false;
    },

    addRow() {
      if (this.searchType == this.$t("label.L0106")) {
        this.propTitle = "검지기 정보 등록";
        this.propType = "create";
        this.$refs.createE.createpopup();
      } else {
        this.propTitle = "카메라 정보 등록";
        this.propType = "create";
        this.$refs.createC.createpopup();
      }
    },

    updateRow(data) {
      if (this.searchType == this.$t("label.L0106")) {
        // originItems = getList 의 모든 값이 들어있는 변수
        // 현재 items (data) 에는 화면에 표시될 요소만 가진 List가 들어감
        data = this.originItems.find(
          (item) => data.item.equipId == item.equipId
        );
        this.propTitle = this.$t("label.L0156");
        this.propType = "update";
        this.$refs.updateE.updatepopup(data);
      } else {
        data = this.originItems.find(
          (item) => data.item.cameraId == item.cameraId
        );
        this.propTitle = this.$t("label.L0157");
        this.propType = "update";
        this.$refs.updateC.updatepopup(data);
      }
    },

    detailRow(data) {
      if (this.searchType == this.$t("label.L0106")) {
        data = this.originItems.find(
          (item) => data.item.equipId == item.equipId
        );
        this.propTitle = this.$t("label.L0158");
        this.propType = "detail";
        this.$refs.detailE.detailpopup(data);
      } else {
        data = this.originItems.find(
          (item) => data.item.cameraId == item.cameraId
        );
        this.propTitle = this.$t("label.L0159");
        this.propType = "detail";
        this.$refs.detailC.detailpopup(data);
      }
    },

    async deleteRow(data) {
      this.$swal({
        heightAuto: false,
        title: this.$t('message.M0006'),
        icon: "info",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: this.$t("button.B0001"),
        cancelButtonText: this.$t("button.B0002"),
        reverseButtons: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.deleteFunction(data.item);
          this.refreshFunction();
        }
      });
    },

    tabFunction(data) {
      this.searchType = data;

      if (data === this.$t('label.L0106')) {
        this.filter = "";
        this.tempfilter = "";
        this.currentPage = 1;
        this.type = "equip";
        this.fields = this.detectorFields;
        this.sortValue = "location";
        this.refreshFunction();
      } else {
        this.filter = "";
        this.tempfilter = "";
        this.currentPage = 1;
        this.type = "camera";
        this.fields = this.cameraFields;
        this.sortValue = "location";
        this.refreshFunction();
      }
    },

    async updateEquipFunction(data) {
      await this.updateFunction(data);
      this.refreshFunction();
    },

    async createEquipFunction(data) {
      const response = await this.createFunction(data);
      if (response.data.success == false) {
        this.errorAlert(response.data.error.message);
      } else {
        this.$refs.createE.hideFunction();
        this.filter = "";
        this.tempfilter = "";
        this.refreshFunction();
      }
    },

    async updateCameraFunction(data) {
      const response = await this.updateFunction(data);

      this.refreshFunction();

      return response;
    },

    async createCameraFunction(data) {
      const response = await this.createFunction(data);
      if (response.data.success == false) {
        this.errorAlert(response.data.error.message);
      } else {
        this.$refs.createC.hideFunction();
        this.filter = "";
        this.tempfilter = "";
        this.refreshFunction();
      }
    },

    async errorAlert(message) {
      this.$swal({
        heightAuto: false,
        title: message,
        icon: "info",
        buttons: true,
        showCancelButton: false,
        confirmButtonText: this.$t("button.B0001"),
        reverseButtons: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
      });
    },

    async refreshFunction() {
      // 검색 조건 초기화
      this.SB_techCodeData = "ALL";
      this.SB_yugoYnData = "ALL";
      this.SB_tunnelCodeData = "ALL";
      this.items = await this.getList();
      this.filterItems = this.items;
    },
  },
  async mounted() {
    let day = new dayjs();
    this.searchEndDate = day.format("YYYYMMDDHHmmssSSS");
    this.searchStartDate = day.subtract(1, "month").format("YYYYMMDDHHmmssSSS");
    this.fields = this.detectorFields;
  },
  async created() {
    this.refreshFunction();
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.badge {
  min-width: 62.139px;
}

// .addBtn{
//     margin-right: -13px;
//   }
</style>
<style scoped>
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.input-group-merge {
  margin-left: -13px;
}
.table td.textRight {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  text-align: right !important;
}
</style>
