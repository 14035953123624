<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0001')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      size="lg"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      body-class="bodyCustomCamera"
      footer-class="footerCustomCamera"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <fieldset class="sectionFieldset form-group">
            <!-- <legend class="col-form-label">{{ $t("label.L0031") }}</legend> -->

            <b-row style="height: 51.99px; margin-top: 15px;">
              <b-col md="2">
                <label for=""
                  >{{ $t("label.L0017")
                  }}<span class="requiredStar">*</span></label
                >
              </b-col>
              <b-col md="4">
                <v-select
                  v-model="cameraInfo.tunnelCode"
                  :clearable="false"
                  label="text"
                  :reduce="(d) => d.data"
                  :options="tunnelCodePopup"
                  :disabled="propType == 'detail' ? true : false"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <label for=""
                  >{{ $t("label.L0009")
                  }}<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    name="cameraId"
                    rules="required|equipIdCheck"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      autocomplete="off"
                      v-model="cameraInfo.cameraId"
                      type="text"
                      maxLength="20"
                      :readonly="
                        propType == 'detail'
                          ? true
                          : propType == 'update'
                          ? true
                          : false
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <label for="" style="margin-left: 40px"
                  >{{ $t("label.L0176")
                  }}<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    name="cameraName"
                    rules="required|equipNameCheck"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      autocomplete="off"
                      maxLength="20"
                      v-model="cameraInfo.cameraName"
                      type="text"
                      :readonly="propType == 'detail' ? true : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col md="2"
                ><label for=""
                  >연관 검지기<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="10">
                <b-form-group>
                  <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    v-model="equipInfo"
                    :options="equipIdList"
                    label="display_name"
                    value="equip_id"
                    :clearable="false"
                  />
                  <small class="text-danger" v-if="errors[0]!=null">
                    검지기 종류는 필수 입니다.</small>
                    </validation-provider>
                </b-form-group>
              </b-col>
            </b-row> -->
            <b-row>
              <b-col md="2" style="padding-right: 7px"
                ><label for=""
                  >{{ $t("label.L0182")
                  }}<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="10">
                <b-form-group>
                  <validation-provider
                    name="rtspUrl"
                    rules="required|rtspurlcheck"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      autocomplete="off"
                      maxlength="100"
                      v-model="cameraInfo.rtspUrl"
                      type="text"
                      :readonly="propType == 'detail' ? true : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- <div>
              <b-row style="min-height: 51.99px" v-if="propType != 'detail'">
                <b-col md="2" style="padding-bottom: 15px">
                  <label for="" style="padding-top: 0px">{{ $t("label.L0175") }}</label></b-col
                >
                <b-col md="6" style="padding-top: 5px">
                  <b-button-group size="sm">
                    <b-button
                      @click="cameraInfo.cameraDeviceType = '디지털'"
                      :pressed="
                        cameraInfo.cameraDeviceType == '디지털' ? true : false
                      "
                      variant="outline-primary"
                      style="width: 70.17px"
                      >{{ $t("label.L0183") }}</b-button
                    >
                    <b-button
                      @click="cameraInfo.cameraDeviceType = '아날로그'"
                      :pressed="
                        cameraInfo.cameraDeviceType == '아날로그' ? true : false
                      "
                      variant="outline-primary"
                      style="
                        width: 70.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      >{{ $t("label.L0184") }}</b-button
                    >
                    <b-button
                      @click="cameraInfo.cameraDeviceType = '기타'"
                      :pressed="
                        cameraInfo.cameraDeviceType == '기타' ? true : false
                      "
                      variant="outline-primary"
                      style="width: 70.17px"
                      >{{ $t("label.L0185") }}</b-button
                    >
                  </b-button-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      name="name"
                      #default="{ errors }"
                      rules="equipNameCheck"
                    >
                      <b-form-input
                        v-show="cameraInfo.cameraDeviceType == '기타'"
                        autocomplete="off"
                        v-model="cameraInfo.cameraDeviceTypeEtc"
                        type="text"
                        maxLength="20"
                      />
                      <small
                        v-show="cameraInfo.cameraDeviceType == '기타'"
                        class="text-danger"
                      >
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                style="min-height: 51.99px"
                v-else-if="propType == 'detail'"
              >
                <b-col md="2" style="padding-bottom: 15px">
                  <label for="">{{ $t("label.L0175") }}</label></b-col
                >
                <b-col md="4" style="padding-top: 5px">
                  <b-form-group>
                    <b-form-input
                      autocomplete="off"
                      v-model="cameraInfo.cameraDeviceType"
                      type="text"
                      :readonly="true"
                      maxLength="40"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row style="min-height: 51.99px" v-if="propType != 'detail'">
                <b-col md="2" style="padding-bottom: 15px"
                  ><label for="" style="padding-top: 0px">{{ $t("label.L0186") }}</label></b-col
                >
                <b-col md="6" style="padding-top: 5px">
                  <b-button-group size="sm">
                    <b-button
                      @click="cameraInfo.cameraType = '고정형'"
                      :pressed="
                        cameraInfo.cameraType == '고정형' ? true : false
                      "
                      variant="outline-primary"
                      style="width: 70.17px"
                      >{{ $t("label.L0187") }}</b-button
                    >
                    <b-button
                      @click="cameraInfo.cameraType = 'PTZ형'"
                      :pressed="cameraInfo.cameraType == 'PTZ형' ? true : false"
                      variant="outline-primary"
                      style="width: 70.17px"
                      >PTZ</b-button
                    >
                    <b-button
                      @click="cameraInfo.cameraType = '기타'"
                      :pressed="cameraInfo.cameraType == '기타' ? true : false"
                      variant="outline-primary"
                      style="width: 70.17px"
                      >{{ $t("label.L0185") }}</b-button
                    >
                  </b-button-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      name="name"
                      #default="{ errors }"
                      rules="equipNameCheck"
                    >
                      <b-form-input
                        v-show="cameraInfo.cameraType == '기타'"
                        autocomplete="off"
                        v-model="cameraInfo.cameraTypeEtc"
                        type="text"
                        maxLength="20"
                      />
                      <small
                        v-show="cameraInfo.cameraType == '기타'"
                        class="text-danger"
                      >
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                style="min-height: 51.99px"
                v-else-if="propType == 'detail'"
              >
                <b-col md="2" style="padding-bottom: 15px">
                  <label for="">{{ $t("label.L0186") }}</label></b-col
                >
                <b-col md="4" style="padding-top: 5px">
                  <b-form-group>
                    <b-form-input
                      autocomplete="off"
                      v-model="cameraInfo.cameraType"
                      type="text"
                      :readonly="true"
                      maxLength="40"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row style="min-height: 51.99px" v-if="propType != 'detail'">
                <b-col md="2" style="padding-bottom: 15px"
                  ><label for="">{{ $t("label.L0188") }}</label></b-col
                >
                <b-col md="6" style="padding-top: 5px">
                  <b-button-group size="sm">
                    <b-button
                      @click="cameraInfo.resolution = 'HD'"
                      :pressed="cameraInfo.resolution == 'HD' ? true : false"
                      variant="outline-primary"
                      style="width: 70.17px"
                      >HD</b-button
                    >
                    <b-button
                      @click="cameraInfo.resolution = 'FHD'"
                      :pressed="cameraInfo.resolution == 'FHD' ? true : false"
                      variant="outline-primary"
                      style="width: 70.17px"
                      >FHD</b-button
                    >
                    <b-button
                      @click="cameraInfo.resolution = '4K UHD'"
                      :pressed="
                        cameraInfo.resolution == '4K UHD' ? true : false
                      "
                      variant="outline-primary"
                      style="
                        width: 70.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      >4K UHD</b-button
                    >
                    <b-button
                      @click="cameraInfo.resolution = '8K UHD'"
                      :pressed="
                        cameraInfo.resolution == '8K UHD' ? true : false
                      "
                      variant="outline-primary"
                      style="
                        width: 70.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      >8K UHD</b-button
                    >
                    <b-button
                      @click="cameraInfo.resolution = '기타'"
                      :pressed="cameraInfo.resolution == '기타' ? true : false"
                      variant="outline-primary"
                      style="width: 70.17px"
                      >{{ $t("label.L0185") }}</b-button
                    >
                  </b-button-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      name="name"
                      #default="{ errors }"
                      rules="equipNameCheck"
                    >
                      <b-form-input
                        v-show="cameraInfo.resolution == '기타'"
                        autocomplete="off"
                        v-model="cameraInfo.resolutionEtc"
                        type="text"
                        maxLength="10"
                      />
                      <small
                        v-show="cameraInfo.resolution == '기타'"
                        class="text-danger"
                      >
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                style="min-height: 51.99px"
                v-else-if="propType == 'detail'"
              >
                <b-col md="2" style="padding-bottom: 15px">
                  <label for="">{{ $t("label.L0188") }}</label></b-col
                >
                <b-col md="4" style="padding-top: 5px">
                  <b-form-group>
                    <b-form-input
                      autocomplete="off"
                      v-model="cameraInfo.resolution"
                      type="text"
                      :readonly="true"
                      maxLength="40"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div> -->
          <!-- </fieldset>









          
          <fieldset class="sectionFieldset form-group">
            <legend class="col-form-label">{{ $t("label.L0173") }}</legend> -->
            <b-row style="height: 51.99px">
              <b-col md="2">
                <label>
                  {{ $t("label.L0087")
                  }}<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="4">
                <b-form-group v-if="propType != 'detail'">
                  <b-button-group size="lm">
                    <b-button
                      variant="outline-primary"
                      @click="cameraInfo.bound = '0'"
                      :pressed="cameraInfo.bound == '0' ? true : false"
                      >{{ $t("label.L0020") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      @click="cameraInfo.bound = '1'"
                      :pressed="cameraInfo.bound == '1' ? true : false"
                      >{{ $t("label.L0019") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
                <b-form-group v-else-if="propType == 'detail'">
                  <b-form-input
                    autocomplete="off"
                    v-model="cameraInfo.boundText"
                    type="text"
                    :readonly="true"
                    maxLength="40"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <label style="margin-left: 40px">
                  {{ $t("label.L0179")
                  }}<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="4">
                <b-form-group v-if="propType != 'detail'">
                  <b-button-group size="lm">
                    <b-button
                      variant="outline-primary"
                      @click="cameraInfo.direction = '0'"
                      :pressed="cameraInfo.direction == '0' ? true : false"
                      >{{ $t("label.L0180") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      @click="cameraInfo.direction = '1'"
                      :pressed="cameraInfo.direction == '1' ? true : false"
                      >{{ $t("label.L0181") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
                <b-form-group v-else-if="propType == 'detail'">
                  <b-form-input
                    autocomplete="off"
                    v-model="cameraInfo.directionText"
                    type="text"
                    :readonly="true"
                    maxLength="40"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row style="height: 51.99px">
              <b-col md="2">
                <label>
                  {{ $t("label.L0071")
                  }}<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="4">
                <b-form-group v-if="propType != 'detail'">
                  <b-button-group size="lm">
                    <b-button
                      variant="outline-primary"
                      @click="cameraInfo.yugoDetectorYn = 'Y'"
                      :pressed="cameraInfo.yugoDetectorYn == 'Y' ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      @click="cameraInfo.yugoDetectorYn = 'N'"
                      :pressed="cameraInfo.yugoDetectorYn == 'N' ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
                <b-form-group v-else-if="propType == 'detail'">
                  <b-form-input
                    autocomplete="off"
                    v-model="cameraInfo.yugoDetectorYnText"
                    type="text"
                    :readonly="true"
                    maxLength="40"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <label>
                  {{ $t("label.L0052")
                  }}<span class="requiredStar">*</span></label
                ></b-col
              >
              <b-col md="4">
                <b-form-group style="margin-left: 0px">
                  <validation-provider
                    name="position"
                    rules="required|decimalPoint"
                    v-slot="{ errors }"
                  >
                  <!-- <validation-provider
                    name="position"
                    rules="required|decimalPoint|check_position"
                    v-slot="{ errors }"
                  > -->
                    <b-input-group class="input-group-merge">
                      <!-- 1000자리이하 -->
                      <b-form-input
                        class="text-right"
                        autocomplete="off"
                        v-model="cameraInfo.formattedPosition"
                        type="text"
                        maxLength="5"
                        :readonly="propType == 'detail' ? true : false"
                      />
                      <!-- <b-form-input
                        class="text-right"
                        autocomplete="off"
                        v-model="cameraInfo.position"
                        type="text"
                        maxLength="5"
                        :readonly="propType == 'detail' ? true : false"
                      /> -->

                      <b-input-group-append is-text>
                        <span style="color: #999999; margin: 0.1rem 0px 0px 0.1rem;">m</span>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col md="2">
                <label style="margin-left: 40px">
                  {{ $t("label.L0174") }}<span class="requiredStar">*</span>
                </label>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider>
                    <v-select
                      v-model="cameraInfo.instHeight"
                      :options="optionsInstHeight"
                      label="text"
                      :reduce="(tt) => tt.data"
                      :clearable="false"
                      :searchable="false"
                      :disabled="propType == 'detail' ? true : false"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col> -->
            </b-row>

            <!-- <b-row>
              <b-col md="2"> <label> 위치</label></b-col>
              <b-col md="4">
                <b-form-group>
                  <b-form-input v-model="cameraInfo.instType"  maxlength="20"    autocomplete="off" type="text" />
                </b-form-group>
              </b-col>
            </b-row> -->
            <!--<b-row style="min-height:51.99px">
              <b-col md="2" style="padding-bottom: 15px"> <label >구조물 유형</label></b-col>
              <b-col md="6" style="padding-top: 5px;"> -->
            <!-- <b-form-group>
                  <v-select
                    v-if="cameraInfo.bracketType != '기타'"
                    v-model="cameraInfo.bracketType"
                    :options="optionCameraStructureType"
                    :clearable="false"
                  />
                  <b-input-group class="input-group-merge" v-else>
                    <b-form-input
                      v-model="cameraInfo.bracketTypeEtc"
                      type="text"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        icon="XIcon"
                        @click="cameraInfo.bracketType = ''"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group> -->
            <!-- <b-button-group size="sm">
                    <b-button 
                    @click="cameraInfo.bracketType='갠트리'"
                    :pressed="cameraInfo.bracketType =='갠트리' ? true:false"
                    variant="outline-primary" style="width:70.17px">갠트리</b-button>
                    <b-button 
                    @click="cameraInfo.bracketType='폴'"
                    :pressed="cameraInfo.bracketType =='폴' ? true:false" 
                    variant="outline-primary" style="width:70.17px">폴</b-button>
                    <b-button 
                    @click="cameraInfo.bracketType='암'"
                    :pressed="cameraInfo.bracketType =='암' ? true:false" 
                    variant="outline-primary" style="width:70.17px">암</b-button>
                    <b-button 
                    @click="cameraInfo.bracketType='기타'"
                    :pressed="cameraInfo.bracketType =='기타' ? true:false" 
                    variant="outline-primary" style="width:70.17px">기타</b-button>
                   </b-button-group>
              </b-col>
              <b-col md="4">
                  <b-form-group>
                <validation-provider  name="name"  #default="{ errors }" rules="equipNameCheck">
                   <b-form-input
                   v-show="cameraInfo.bracketType =='기타'"
                      v-model="cameraInfo.bracketTypeEtc"
                      autocomplete="off"
                      type="text"
                      maxlength="20"
                    />
                    <small class="text-danger" >
                    {{ errors[0]}}
                  </small>
                  </validation-provider>
                </b-form-group>
                </b-col>
             </b-row> -->
          </fieldset>
          <!-- <fieldset  class="sectionFieldset form-group" >
            <legend  class="col-form-label">기타</legend>
            <b-row>
          </b-row>
          </fieldset> -->
        </b-form>
      </validation-observer>
      <!-- <template #modal-header>
        <h5 style="padding-top: 10px">{{ propTitle }}</h5>
        <b-button
          variant="outline-primary"
          style="
            margin: 0 0 0 auto;
            width: 41.81px;
            padding-left: 0px;
            padding-right: 0px;
          "
          @click="langTypeFunction"
        >
          {{ langType }}
        </b-button>
      </template> -->
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
} from "bootstrap-vue";

export default {
  name: "equipPopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButtonGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
  },
  props: {
    propTitle: String,
    propType: String,
    tunnelCodePopup: Array,
  },
  data() {
    return {
      gsGlobalTarget: {
        directionUp:
          this.$store.getters["settingConfig/getTarget"]("gs").direction.up,
        directionDown:
          this.$store.getters["settingConfig/getTarget"]("gs").direction.down,
      },
      optionsBound: [
        {
          text: this.$t("label.L0020"),
          data: "0",
        },
        { text: this.$t("label.L0019"), data: "1" },
      ],
      equipIdList: null,
      langType: "가",
      equipInfo: {
        equip_id: "",
        equipName: "",
        display_name: "",
      },
      cameraInfo: {
        equipId: "",
        tunnelCode: "", // 터널코드
        cameraDeviceType: "디지털", // CCTV 종류 선택
        cameraDeviceTypeEtc: "", // CCTV 종류 선택 (기타)
        cameraName: "", // CCTV 명칭
        cameraId: "", // CCTV ID
        cameraType: "고정형", // CCTV 유형
        cameraTypeEtc: "", // CCTV 유형 (기타)
        resolution: "HD", // CCTV 해상도
        resolutionEtc: "", // CCTV 해상도 (기타)
        rtspUrl: "rtsp://", // 영상 연결 주소
        direction: "0", // 방향
        cameraIndex: null, // 연계 순번
        bracketType: "갠트리", // 구조물 유형
        bracketTypeEtc: "", // 구조물 유형 (기타)
        direction: "0", // 설치 방향
        position: 0, // 설치이정
        formattedPosition : 0, // 설치이정 m 변경 
        instType: "", // 설치위치
        instHeight: 0, // 설치높이
        bound: 0,
        linkCode: "",
        road: "",
        yugoDetectorYn: "N",
      },
      // optionDisplay: [
      //   "V-4020E ( V-0010-4020E-R-5-H )",
      //   "V-3925S ( V-0010-3925S-R-5-H )",
      //   "V-3950S ( V-0010-3950S-R-4-H )",
      // ],
      // optionsCamera: ["디지털", "아날로그", "기타"],
      // optionsCameraType: ["고정형", "PTZ형", "기타"],
      // optionCameraResolution: ["HD", "FHD", "4K UHD", "8K UHD", "기타"],
      // optionCameraStructureType: ["갠트리", "폴", "암", "기타"],
      optionsInstHeight: [
        {
          // text: "1단",
          text: this.$t("label.L0190"),
          data: "0",
        },
        {
          // text: "2단",
          text: this.$t("label.L0191"),
          data: "1",
        },
        {
          // text: "3단",
          text: this.$t("label.L0192"),
          data: "2",
        },
      ],
      options: [],
    };
  },
  methods: {
    async onBtnOk(data) {
      data.preventDefault();
      const success = await this.$refs.simpleRules.validate();
      if (success) {
        if ("기타" === this.cameraInfo.cameraDeviceType)
          this.cameraInfo.cameraDeviceType =
            this.cameraInfo.cameraDeviceTypeEtc;
        if ("기타" === this.cameraInfo.cameraType)
          this.cameraInfo.cameraType = this.cameraInfo.cameraTypeEtc;
        if ("기타" === this.cameraInfo.resolution)
          this.cameraInfo.resolution = this.cameraInfo.resolutionEtc;
        if ("기타" === this.cameraInfo.bracketType)
          this.cameraInfo.bracketType = this.cameraInfo.bracketTypeEtc;
        this.setData();

        // demoVideo 추가 
        let testVideoNum = Math.floor(Math.random() * 9) + 1;
        this.cameraInfo.setction = testVideoNum;

        this.cameraInfo.position = this.changePositionFormat(this.cameraInfo.formattedPosition, "km");


        if (this.propType == "update") {
          this.$emit("update-obj", this.cameraInfo);
          this.hideFunction();
        } else {
          this.cameraInfo.bound = Number(this.cameraInfo.bound);
          this.$emit("create-obj", this.cameraInfo);
        }
      }
    },
    onBtnCancel() {},
    initSetData() {
      // let roadConfig = this.$storage.data[this.$storage.data["local"]];
      // this.detectorInfo.road = roadConfig[0].road;
      // this.linkCode = roadConfig[0].linkCode;
    },
    updatepopup(data) {
      if (data) {
        this.cameraInfo = { ...data };
        // this.cameraInfo = { ...data.item };
        this.cameraInfo.formattedPosition = this.changePositionFormat(this.cameraInfo.position, "m")

        this.initSetData();
        if (
          ["아날로그", "디지털"].indexOf(this.cameraInfo.cameraDeviceType) ===
          -1
        ) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.cameraDeviceType !== "") {
            this.cameraInfo.cameraDeviceTypeEtc =
              this.cameraInfo.cameraDeviceType;
            this.cameraInfo.cameraDeviceType = "기타";
          }
        }

        if (["고정형", "PTZ형"].indexOf(this.cameraInfo.cameraType) === -1) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.cameraType !== "") {
            this.cameraInfo.cameraTypeEtc = this.cameraInfo.cameraType;
            this.cameraInfo.cameraType = "기타";
          }
        }
        if (
          ["HD", "FHD", "4K UHD", "8K UHD"].indexOf(
            this.cameraInfo.resolution
          ) === -1
        ) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.resolution !== "") {
            this.cameraInfo.resolutionEtc = this.cameraInfo.resolution;
            this.cameraInfo.resolution = "기타";
          }
        }
        if (
          ["갠트리", "폴", "암"].indexOf(this.cameraInfo.bracketType) === -1
        ) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.bracketType !== "") {
            this.cameraInfo.bracketTypeEtc = this.cameraInfo.bracketType;
            this.cameraInfo.bracketType = "기타";
          }
        }
      }
      for (let i in this.equipIdList) {
        if (this.equipIdList[i].equip_id == this.cameraInfo.equipId) {
          this.equipInfo = this.equipIdList[i];
        }
      }

      this.$refs.data.show();
    },
    detailpopup(data) {
      if (data) {
        this.cameraInfo = { ...data };
        this.cameraInfo.formattedPosition = this.changePositionFormat(this.cameraInfo.position, "m")

        this.cameraInfo.boundText =
          this.cameraInfo.bound == 0
            ? this.$t("label.L0020")
            : this.$t("label.L0019");
        this.cameraInfo.directionText =
          this.cameraInfo.direction == 0
            ? this.$t("label.L0180")
            : this.$t("label.L0181");
        this.cameraInfo.yugoDetectorYnText =
          this.cameraInfo.yugoDetectorYn == "Y"
            ? this.$t("label.L0126")
            : this.$t("label.L0127");
        // this.cameraInfo = { ...data.item };
        this.initSetData();
        if (
          ["아날로그", "디지털"].indexOf(this.cameraInfo.cameraDeviceType) ===
          -1
        ) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.cameraDeviceType !== "") {
            this.cameraInfo.cameraDeviceTypeEtc =
              this.cameraInfo.cameraDeviceType;
            this.cameraInfo.cameraDeviceType = "기타";
          }
        }

        if (["고정형", "PTZ형"].indexOf(this.cameraInfo.cameraType) === -1) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.cameraType !== "") {
            this.cameraInfo.cameraTypeEtc = this.cameraInfo.cameraType;
            this.cameraInfo.cameraType = "기타";
          }
        }
        if (
          ["HD", "FHD", "4K UHD", "8K UHD"].indexOf(
            this.cameraInfo.resolution
          ) === -1
        ) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.resolution !== "") {
            this.cameraInfo.resolutionEtc = this.cameraInfo.resolution;
            this.cameraInfo.resolution = "기타";
          }
        }
        if (
          ["갠트리", "폴", "암"].indexOf(this.cameraInfo.bracketType) === -1
        ) {
          // inst_location 에서 아무값 선택 안했을 때 '기타' 선택되는 문제
          if (this.cameraInfo.bracketType !== "") {
            this.cameraInfo.bracketTypeEtc = this.cameraInfo.bracketType;
            this.cameraInfo.bracketType = "기타";
          }
        }
      }
      for (let i in this.equipIdList) {
        if (this.equipIdList[i].equip_id == this.cameraInfo.equipId) {
          this.equipInfo = this.equipIdList[i];
        }
      }

      this.$refs.data.show();
    },
    changePositionFormat(value, type){
      if(type === "m"){
        if (value) {
          return (value * 1000).toFixed(0);
        }else {
          return '';
        }
      }else if(type === "km"){
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          return (numValue / 1000).toFixed(2);
        }else {
          return '';
        }
      }else{
        return '';
      }
    },
    
    createpopup() {
      let defaultTunnelCode = this.tunnelCodePopup[0].data; // 모든 터널중 첫번째 터널로 설정
      this.equipInfo = {
        equip_id: "",
        equipName: "",
        display_name: "없음",
      };
      this.cameraInfo = {
        equipId: "",
        tunnelCode: defaultTunnelCode, // 터널코드
        cameraDeviceType: "", // CCTV 종류 선택
        cameraDeviceTypeEtc: "", // CCTV 종류 선택 (기타)
        cameraName: "", // CCTV 명칭
        cameraId: "", // CCTV ID
        cameraType: "", // CCTV 유형
        cameraTypeEtc: "", // CCTV 유형 (기타)
        resolution: "", // CCTV 해상도
        resolutionEtc: "", // CCTV 해상도 (기타)
        rtspUrl: "rtsp://", // 영상 연결 주소
        direction: "0", // 방향
        cameraIndex: null, // 연계 순번
        bracketType: "", // 구조물 유형
        bracketTypeEtc: "", // 구조물 유형 (기타)
        direction: "0", // 설치 방향
        position: null, // 설치이정
        formattedPosition : 0, // 설치이정 m 변경 
        instType: "", // 설치위치
        instHeight: "0", // 설치높이
        bound: 0,
        linkCode: "",
        road: "",
        yugoDetectorYn: "N",
      };
      this.$refs.data.show();
    },
    setData() {
      // this.cameraInfo.linkCode = this.linkCode;
      // this.cameraInfo.linkCode =
      //   this.cameraInfo.bound == 0
      //     ? "LNK-0450-MCT"
      //     : "LNK-0450-CMT";

      // if(this.cameraInfo.bound == 0){// 하행
      //   this.cameraInfo.road = this.gsGlobalTarget.directionDown;
      // }else if (this.cameraInfo.bound == 1) { // 상행
      //   this.cameraInfo.road = this.gsGlobalTarget.directionUp;
      // }

      // this.cameraInfo.equipTab = this.equipTab;
      this.cameraInfo.equipId = this.equipInfo.equip_id;
      // this.cameraInfo.instHeight ==''? this.cameraInfo.instHeight= null : ''
    },
    getEquipIdList: function () {
      // this.equipIdList = [
      //   { equip_id: "", equipName: "", display_name: "없음" },
      // ];
      // this.api
      //   .getEquipmentIdList({
      //     // linkCode: this.linkCode,
      //   })
      //   .then((res) => {
      //     var equipIdList = res.data.data.equipIdList;
      //     this.equipIdList.push(...equipIdList);
      //     var index = equipIdList.findIndex(
      //       (d) => d.equip_id == this.cameraInfo.equipId
      //     );
      //     if (index > -1) this.changeEquipId(equipIdList[index]);
      //     else this.changeEquipId(this.equipIdList[0]);
      //     console.log('this.equipIdList ::: ', this.equipIdList)
      //     console.log('this.cameraInfo ::: ', this.cameraInfo)
      //   });
    },
    changeEquipId: function (equip) {
      this.equipInfo.displayName = equip.display_name;
      this.equipInfo.equipId = equip.equip_id;
      this.equipInfo.equipName = equip.equip_name;
    },
    hideFunction() {
      this.$refs["data"].hide();
    },
  },
  mounted() {
    // var roadConfig = this.$storage.data[this.$storage.data["local"]];

    // // this.roadConfig = roadConfig;
    // // this.detectorInfo.road = roadConfig[0].road;
    // this.linkCode = roadConfig[0].linkCode;
    this.getEquipIdList();

    // this.initData();
  },
};
</script>
<style scoped>
.sectionFieldset {
  border: solid 1px rgba(0, 0, 0, 0.125);
  padding-top: 0px;
  padding-right: 12px;
  padding-bottom: 0px;
  padding-left: 12px;
}

.sectionFieldset legend {
  width: inherit;
  /* Or auto */
  padding: 0 10px;
  /* To give a bit of padding on the left and right */
  border-bottom: none;
  font-weight: bold;
  font-size: 12px;
}
.sectionFieldset .custom-control-inline {
  margin-right: 2rem;
}
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}
.rowSize {
  text-align: left;
  align-items: center;
  padding-bottom: 10px;
}

.input-group-text {
  padding-left: 5px !important;
  padding-right: 5px !important;
  background-color: #efefef;
}
.input-group-merge {
  margin-left: 0px;
}
</style>
<style>
.bodyCustomCamera {
  padding: 1rem 1.7rem 0.4rem 1.7rem;
}
.footerCustomCamera {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 18px !important;
}
</style>
